<template>
  <div class="wrap">
    <div class="row justify-content-between">
      <div class="col-12 col-sm-6 col-lg-4">
        <img class="logo" :src="require(`@/assets/svg/es-logo.svg`)" alt="Пространство роста и новых стартов">
        <div class="title">Пространство роста и новых стартов</div>
        <a class="button" href="https://elsspace.ru/?gcpc=2c40d" target="_blank"
           referrerpolicy="no-referrer">Поехали</a>
      </div>
      <div class="col-1 col-lg-12"/>
      <div class="col-12 col-sm-5 col-lg-4">
        <img class="qr" :src="require(`@/assets/png/es-qr-code.png`)" alt="Пространство роста и новых стартов">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ElsSpaceBanner"
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;500;600&family=Play:wght@400;700&display=swap');

.wrap {
  background: url(../assets/png/es-banner-mob.png) no-repeat center, #FFFFFF;
  background-size: cover;
  padding: 32px 24px 76px;
  font-family: 'Fira Sans', sans-serif;
  position: relative;
  margin: 0 0 100px 0;
  @include _576 {
    padding: 40px 48px 80px;
    background: url(../assets/png/es-banner-tablet.png) no-repeat center, #FFFFFF;
    background-size: cover;
  }
  @include _992 {
    padding: 60px 60px 48px;
    background: url(../assets/png/es-banner-desk.png) no-repeat right center, #F3F3F3;
    background-size: contain;
  }

  &:before {
    content: '';
    width: 195px;
    height: 195px;
    position: absolute;
    left: 0;
    bottom: 0;
    background: url(../assets/png/es-banner-cube-2.png) no-repeat left bottom;
    background-size: contain;
    z-index: 3;
    @include _576 {
      width: 240px;
      height: 240px;
      background: url(../assets/png/es-banner-cube-2-tablet.png) no-repeat left bottom;
      background-size: contain;
      left: 40%;
    }
    @include _768 {
      width: 265px;
      height: 265px;
    }
    @include _992 {
      height: 108%;
      width: 50%;
      background: url(../assets/png/es-banner-bekr.png) no-repeat center bottom;
      background-size: contain;
      right: 0;
      left: auto;
    }
  }

  &:after {
    content: '';
    width: 159px;
    height: 159px;
    position: absolute;
    right: 0;
    bottom: 0;
    background: url(../assets/png/es-banner-cube-1.png) no-repeat right bottom;
    background-size: contain;
    z-index: 1;
    @include _992 {
      content: none;
    }
  }

  .logo {
    max-width: 132px;
    height: auto;
    margin: 0 0 24px 0;
    position: relative;
    z-index: 5;
    @include _992 {
      max-width: 322px;
      width: 100%;
    }
  }

  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 116%;
    color: #727683;
    max-width: 224px;
    margin: 0 0 29px 0;
    position: relative;
    z-index: 5;
    @include _576 {
      max-width: 240px;
      margin: 0 0 36px 0;
    }
    @include _992 {
      font-size: 38px;
      line-height: 116%;
      margin: 0 0 48px 0;
      max-width: 100%;
    }
  }

  .button {
    font-family: 'Play', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 120%;
    color: #F7F7F7;
    text-transform: uppercase;
    background: linear-gradient(91.67deg, #FF00B8 -7.4%, #54D6FF 102.31%);
    padding: 13px 70px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    margin: 0 auto 40px;
    position: relative;
    z-index: 5;
    @include _576 {
      display: inline-flex;
      width: 240px;
    }
    @include _992 {
      font-size: 22px;
      line-height: 120%;
      width: 270px;
      margin: 0 auto 48px;
    }
  }

  .qr {
    position: relative;
    z-index: 2;
    max-width: 100%;
    height: auto;
    @include _576 {
      margin: auto 0 auto auto;
    }
    @include _992 {
      margin: 0;
      max-width: 208px;
    }
  }
}
</style>