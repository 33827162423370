<template>
    <div class="main-banner bg-dagoyel">
        <div class="row">
            <div class="col-12">
                <h1 class="h1 ff-r fw-5 text-uppercase text-center text-sm-start mb-1">
                    Фонд развития
                </h1>
                <h3 class="h3 ff-rc fw-4 ff-rc text-lowercase text-center text-sm-start mb-4">
                    правовой культуры и правосознания
                </h3>
                <h6 class="h6 ff-r fw-4 mb-3 text-center text-sm-start">
                    «Знание законов заключается не в том, чтобы помнить их слова, а в том, чтобы постигать
                    их смысл.»
                </h6>
                <div class="fs-m ff-r fw-4 text-center text-sm-start">
                    © Цицерон, древнеримский философ
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "MainBanner"
    }
</script>

<style lang="scss" scoped>
    .main-banner {
        padding: 45px 16px 225px;
        background-image: url(~@/assets/png/main-banner.png);
        background-repeat: no-repeat;
        background-position: bottom 0 right;
        background-size: 250px;
        margin: 0 0 30px;

        @include _576 {
            padding: 65px 48px;
        }
        @include _768 {
            margin: 0 0 80px;
        }
        @include _992 {
            background-size: 40%;

        }
        @include _1200 {
            background-size: contain;
            background-position: bottom 0 right 70px;
        }

        .h3 {
            @include _992 {
                letter-spacing: .3px;
            }
        }

        .h6 {
            max-width: 100%;
            text-align: center;
            @include _576 {
                max-width: 370px;
            }
            @include _992 {
                max-width: 530px;
            }
        }

        .fs-m {
            font-size: $fs-less;
            @include _992 {
                font-size: $fs-medium;
            }
        }
    }
</style>