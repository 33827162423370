<template>
  <router-link class="card-preview mb-4 mb-md-0" :to="{ name: 'ArticleDetail', params: { code: post.code } }">
    <div class="card-preview-header">
      <img v-if="post.src"
           :src="require(`@/assets/articles/${post.src}`)"
           :alt="post.name"
           class="img-fluid"
      >
    </div>
    <div class="card-preview-body mb-4 mt-3">
      <div class="fs-s fw-4 clr-digray mb-2">
        {{ post.date }}
      </div>
      <div class="fs-b fw-6 ff-m mb-3" v-html="`${post.name.substr(0, 100)}...`"/>
      <div class="fs-b fw-4" v-html="`${post.data[0].substr(0, 100)}...`"/>
    </div>
    <div class="card-preview-footer d-flex flex-column flex-sm-row">
      <template v-if="this.$route.path === '/articles'">
        <div class="button _back_base ff-m fw-7 text-center">
          Читать полностью
        </div>
      </template>
      <template v-else>
        <div class="link _base ff-m fw-7 text-start">
          Читать полностью
        </div>
      </template>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "CardPreview",
  props: {
    post: {
      type: [Array, Object],
      default() {
        return [{
          id: 0,
          date: '',
          src: '',
          code: '',
          name: '',
          data: [],
        }]
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.card-preview {
  display: flex;
  flex-direction: column;

  &-header {
    max-height: 300px;
    height: 230px;
    display: flex;

    img {
      margin: auto;
    }
  }

  @include _768 {
    height: 100%;
  }

  &-footer {
    margin: 24px 0 0 0;
    @include _576 {
      margin: auto 0 0 0;
    }
  }
}
</style>