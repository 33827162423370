<template>
  <div class="row justify-content-center">
    <div class="col-12 col-lg-5 col-xl-4" v-if="tabs.length > 1">
      <div class="row g-2 g-sm-0 g-lg-3 row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-1 mb-2 mb-lg-0">
        <div class="col" :key="`card-tab-${i}`" v-for="(tab, i) in tabs">
          <base-button class="ff-m text-center text-lg-start w-100"
                       :color="['_outline_tab', {'_active': curNum === i}]"
                       :type="'button'"
                       v-on:click="curNum = i">
            {{ tab.tabName }}
          </base-button>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-7 col-xl-8">
      <div class="bg-dagray cur-tab" v-if="curTab">

        <template v-if="curTab.src.length > 0">
          <img class="cur-tab-img" v-if="curTab.src"
               :src="require(`@/assets/svg/${curTab.src}`)"
               :alt="curTab.name">
        </template>
        <template v-else>
          <div class="h6 ff-r fw-7 text-white text-uppercase mb-4" v-html="curTab.title"/>
        </template>

        <template v-if="curTab.type === 'text'">
          <div class="cur-tab-str" v-for="(str, i) in curTab.data" :key="`cur-tab-str-${i}`">
            {{ str }}
          </div>
          <a class="link _dodge ff-m fw-7 text-start" :href="curTab.url" target="_blank">
            <span>{{ curTab.urlName }}</span>
          </a>
        </template>

        <template v-else>
          <div class="cur-tab-str" v-for="(str, i) in curTab.data.slice(0, 2)"
               :key="`cur-tab-str-${i}`"
               v-html="str"/>
          <div class="collapse" :id="`collapse-${curTab.id}`">
            <div class="cur-tab-str" v-for="(str, i) in curTab.data.slice(2)"
                 :key="`cur-tab-str-${i}`"
                 v-html="str"/>
          </div>
          <a class="link _dodge ff-m fw-7 text-start" data-bs-toggle="collapse" :href="`#collapse-${curTab.id}`"
             role="button" aria-expanded="false" @click="switchCollapse()">
            <span>
              {{ !collapseShow ? curTab.urlName : 'Скрыть' }}
            </span>
          </a>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from "../BaseButton";
// import {Collapse} from 'bootstrap'

export default {
  name: "CardPills",
  components: {BaseButton},
  props: {
    tabs: {
      type: Array,
      default() {
        return [{
          id: 0,
          name: '',
          url: '',
          src: '',
          data: [],
        }]
      }
    }
  },
  data() {
    return {
      curNum: 0,
      collapseShow: false,
    }
  },
  computed: {
    curTab() {
      return this.tabs[this.curNum];
    }
  },
  methods: {
    switchCollapse() {
      this.collapseShow = !this.collapseShow;
    }
  }
}
</script>

<style lang="scss" scoped>
.cur-tab {
  padding: 30px 16px;
  @include _992 {
    padding: 30px 40px;
  }

  &-img {
    max-width: 200px;
    height: auto;
    margin: 0 0 36px;
  }

  &-str {
    color: white;
    margin: 0 0 20px;
    @include _992 {
      max-width: 83%;
    }
  }
}
</style>